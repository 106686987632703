<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.nb_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import ApNotificationService from "@/services/ApNotificationService"
export default {
    components:{DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Notification',disabled: false,href:'/notification',},
              {text:'Broadcast',disabled: false,href:'/notification/broadcast',},
              {text:'Send List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Full Name", value:"full_name" },
                { text:"Read Status", value:"read_type_description" },
                { text:"Last Status Date", value:"updated_date" },
                { text:"Created Date", value:"created_date" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            form:{
                merchant_id:0,
                login_id:'',
            },
            routeParam:[],

        }
    },
    async created() {
        this.form['notification_broadcast_id'] = this.$route.params.notification_broadcast_id;
        this.form['nb_name'] = atob(this.$route.params.enc_nb_name);
        this.refreshDataTable();
    },
    mounted(){
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    ApNotificationService.sendlist_notification_broadcast(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Notification Broadcast Send List',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Notification Broadcast Send List','Unknown Error');
                  
                }           
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },
    }

}
</script>